import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../layouts"
import styled from "styled-components"
import Hero from "../components/Hero"
import { typography, color } from "../styles/variables"
import LetterAnimation from "../animations/LetterAnimation"
import animationData from "../animations/DESKTOP_MOBILE/08-SocialSustain.json"
import mobileAnimationData from "../animations/DESKTOP_MOBILE/08-SocialSustain_MOBILE.json"
import { PageContainer } from "../styles/global"
import CircleAndText from "../components/CircleAndText"
import List from "../components/List"
import Item from "../components/List/Item"
import Arrow from "../images/svgs/Explore"
import Line from "../components/Line"
import Img from "gatsby-image"
import StyledStats from "../styles/StyledStats"
import StyledSustainStat from "../styles/StyledSustainStat"
import NextPage from "../components/NextPage"
import Chart1 from "../images/svg/Chart1.svg"
import Chart2 from "../images/svg/Chart2.svg"
import Chart3 from "../images/svg/Chart3.svg"
import Chart4 from "../images/svg/Chart4.svg"
import Chart5 from "../images/svg/Chart5.svg"
import Chart6 from "../images/svg/Chart6.svg"
import Chart7 from "../images/svg/Chart7.svg"
import useIsTablet from "../hooks/useIsTablet"

const StyledSustain = styled.div`
  width: 100%;
  /* padding-top: 7rem; */
  & .sustain_orange {
    color: ${color.header.social_value.color_3};
  }
  & .grey_padding-element.social {
    grid-column: span 2;
  }
`

const SocialValue = ({ location }) => {
  const isTablet = useIsTablet()
  const data = useStaticQuery(graphql`
    query {
      bgImage: file(relativePath: { eq: "social-header.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      social1: file(relativePath: { eq: "social-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      social2: file(relativePath: { eq: "social-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      social4: file(relativePath: { eq: "social-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout location={location}>
      <Hero
        bg="black"
        animationData={animationData}
        mobileAnimationData={mobileAnimationData}
        mobileColors={color.header.social_value}
        bgImage={data.bgImage}
        location={location.pathname}
        headerColor={color.header.social_value.color_3}
      >
        {isTablet ? (
          <h1 className="hero_title">Social sustainability</h1>
        ) : (
          <LetterAnimation duration={0.7} delay={0.017} initDelay={0.1}>
            <h1 className="hero_title text-animated">
              <span className="line-inner">Social</span>
            </h1>
            <h1 className="hero_title text-animated">
              <span className="line-inner">sustainability</span>
            </h1>
          </LetterAnimation>
        )}
      </Hero>

      <CircleAndText image={data.social1}>
        <div
          data-scroll
          data-scroll-speed="1"
          className="circle-text_container first-section"
        >
          <p>At Landsec, our ambition is to deliver positive impact in our communities, creating opportunities for local people and ensuring we have an inclusive industry with the skills we need, both now and in the future.</p>
          <List device="desktop">
            <Item scrollElementClass="overview">
              <p>Overview</p>
              <Arrow />
            </Item>
            <Item scrollElementClass="employment">
              <p>Employment</p>
              <Arrow />
            </Item>
            <Item scrollElementClass="charity-partnerships">
              <p>Charity partnerships</p>
              <Arrow />
            </Item>
            <Item scrollElementClass="volunteering">
              <p>Volunteering</p>
              <Arrow />
            </Item>
            <Item scrollElementClass="education">
              <p>Education</p>
              <Arrow />
            </Item>
          </List>
        </div>
        <List device="tablet">
          <Item scrollElementClass="overview">
            <p>Overview</p>
            <Arrow />
          </Item>
          <Item scrollElementClass="employment">
            <p>Employment</p>
            <Arrow />
          </Item>
          <Item scrollElementClass="charity-partnerships">
            <p>Charity partnerships</p>
            <Arrow />
          </Item>
          <Item scrollElementClass="volunteering">
            <p>Volunteering</p>
            <Arrow />
          </Item>
          <Item scrollElementClass="education">
            <p>Education</p>
            <Arrow />
          </Item>
        </List>
        <Line />
      </CircleAndText>
      <StyledSustain>
        <PageContainer>
          <StyledStats
            align="center"
            className="headline-stats overview no-padding-b-d m-bottom-0"
          >
            <div className="stat_item stat_item--aligned">
              <div className="stat_item__inner">
                <div className="stat_title">
                  <h3 className="sustain_orange">£25m</h3>
                </div>
                <div className="stat_para">
                  <p>Our commitment is to create £25m worth of social value by 2025 through our four social sustainability programmes.</p>
                </div>
              </div>
              <div className="stat_item__inner">
                <div className="stat_title">
                  <h3 className="sustain_orange">£6.5m</h3>
                </div>
                <div className="stat_para">
                  <p>Amount of social value we created in 2020/2021.</p>
                </div>
              </div>
            </div>
            <div className="stat_item stats_image no-transform">
              <Img fluid={data.social4.childImageSharp.fluid} />
            </div>
          </StyledStats>
          <StyledStats style={{ marginTop: 0, paddingTop: 0 }}>
            <div className="grey_padding-element social">
              <p>Social value is the positive impact generated by businesses going above and beyond to benefit people, communities and society as a whole. Our commitment is to create £25m worth of social value by 2025 through our community employment, education, charity partnerships and volunteering programmes.</p>
            </div>
            <Line />
          </StyledStats>
          <StyledSustainStat>
            <div className="social-stats_text employment">
              <h2>Employment</h2>
              <p>Supporting people facing significant barriers with the skills and experience to enter sustainable work.</p>
            </div>
            <div className="social-stats_container">
              <div className="social_stats-row align-center">
                <div className="social-stats_item">
                  <Chart1 />
                </div>
                <div className="social-stats_item">
                  <p className="large-para">
                    Social value added, including:
                  </p>
                </div>
                <Line noPadding />
              </div>
              <div className="social_stats-row">
                <div className="social-stats_item">
                  <Chart2 />
                  <p>Supporting prison leavers</p>
                </div>
                <div className="social-stats_item">
                  <Chart3 />
                  <p>Supporting young people aged 16-25 at risk of long-term unemployment</p>
                </div>
                <Line noPadding />
              </div>
              <div className="social_stats-row">
                <div className="social-stats_item">
                  <Chart4 />
                  <p>Supporting people experiencing homelessness</p>
                </div>
              </div>
            </div>
          </StyledSustainStat>

          <StyledSustainStat>
            <div className="social-stats_text charity-partnerships">
              <h2>Charity partnerships</h2>
              <p>Working with our communities to address and raise awareness of societal issues.</p>
            </div>
            <div className="social-stats_container">
              <div className="social_stats-row align-center">
                <div className="social-stats_item">
                  <Chart5 />
                </div>
                <div className="social-stats_item">
                  <p className="large-para">
                    Value of support given to charities in 2020/2021, including:
                  </p>
                </div>
                <Line noPadding />
              </div>
              <div className="social_stats-row">
                <div className="social-stats_item">
                  <Chart6 />
                  <p>Total amount of direct donations to charities</p>
                </div>
                <div className="social-stats_item">
                  <Chart7 />
                  <p>Value of space donated to charities</p>
                </div>
              </div>
            </div>
          </StyledSustainStat>

          <StyledSustainStat>
            <div className="social-stats_text volunteering">
              <h2>Volunteering</h2>
              <p>Empowering our people and partners to create positive impact by using their skills and expertise throughout 2020/2021.</p>
            </div>
            <div className="social-stats_container">
              <div className="social_stats-row">
                <div className="social-stats_item ">
                  <h2 className="sustain_stat-title orange">719</h2>
                  <p>Volunteering hours</p>
                </div>
                <div className="social-stats_item">
                  <h2 className="sustain_stat-title orange">£99k</h2>
                  <p>Social value created in the last financial year</p>
                </div>
                <Line noPadding />
              </div>
              <div className="social_stats-row">
                <div className="social-stats_item">
                  <h2 className="sustain_stat-title orange">895</h2>
                  <p>Total number of people directly supported</p>
                </div>
                <div className="social-stats_item">
                  <h2 className="sustain_stat-title orange">120</h2>
                  <p>Landsec employees volunteered at least once </p>
                </div>
                <Line noPadding />
              </div>
              <div className="social_stats-row">
                <div className="social-stats_item">
                  <h2 className="sustain_stat-title orange">352</h2>
                  <p>Total volunteer engagements</p>
                </div>
              </div>
            </div>
          </StyledSustainStat>

          <StyledSustainStat className="no-margin-b">
            <div className="social-stats_text education">
              <h2>Education</h2>
              <p>Inspiring young people from diverse socio-economic backgrounds about careers in our industry in 2020/2021.</p>
            </div>
            <div className="social-stats_container">
              <div className="social_stats-row">
                <div className="social-stats_item">
                  <h2 className="sustain_stat-title blue">92</h2>
                  <p>Total young people engaged in formal programmes</p>
                </div>
                <div className="social-stats_item">
                  <h2 className="sustain_stat-title blue">710</h2>
                  <p>
                    Total young people engaged through careers, industry insights and employability sessions.
                  </p>
                </div>
              </div>
            </div>
          </StyledSustainStat>
        </PageContainer>
        <NextPage
          page={{
            title: "Contact",
            path: "contact",
          }}
          colorKey="foreword"
        />
      </StyledSustain>
    </Layout>
  )
}

export default SocialValue
